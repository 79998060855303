/*@import "variables_overrides.scss";*/

/*@import "bootstrap/dist/css/bootstrap.min.css";*/
/*@import "bootstrap-icons/font/bootstrap-icons.min.css";*/
@import "~spectrum-colorpicker/spectrum.css";

@import "forks/pe-icon-7-stroke.css";
@import "forks/dropupload.css";
@import "forks/richtext.min.css";
@import 'tinymce/skins/ui/oxide/skin.css';
@import "bootstrap-fileinput/css/fileinput.css";

/* Autocomplete */
.autocomplete-suggestions { border: 1px solid #999; background: #FFF; overflow: auto; }
.autocomplete-suggestion { padding: 2px 5px; white-space: nowrap; overflow: hidden; }
.autocomplete-selected { background: #F0F0F0; }
.autocomplete-suggestions strong { font-weight: normal; color: #3399FF; }
.autocomplete-group { padding: 2px 5px; }
.autocomplete-group strong { display: block; border-bottom: 1px solid #000; }
.autocomplete-suggestion {
    cursor: pointer;
}


/* Для label */
.df-label-text-large { font-size: 16px !important; }