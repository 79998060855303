/*
 * Переопределение переменных Bootstrap.
 */

//$body-bg: #f4f8f9;
$body-bg: #f8f8f8;

$font-size-base: 0.9rem;

$input-bg: white;
